import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { connect } from "react-redux"
import snsWebSdk from "@sumsub/websdk"
import { withStyles } from "@material-ui/core/styles"
// import Container from "@material-ui/core/Container"
import MuiDivider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Axios from "axios"
import Helmet from "react-helmet"
import { NavLink as RouterNavLink } from "react-router-dom"
import styled from "styled-components"
import { spacing } from "@material-ui/system"
import {
  Breadcrumbs,
  // HomeBreadcrumb,
  StyledBreadcrumb,
  // BreadcrumHomeIcon,
} from "../BreadCrumbs"
import styles from "./styles"
import BasicForm from "./BasicForm"
import { getTenantHeaders } from "../../common/utils"
import NotFound from "../CorporateEntity/NotFound"

const statusMap = [
  { label: "Init", key: "init", code: 1, color: "grey" },
  { label: "Pending", key: "pending", code: 2, color: "orange" },
  { label: "Queued", key: "queued", code: 3, color: "lightblue" },
  { label: "Completed", key: "completed", code: 4, color: "green" },
  { label: "On Hold", key: "onHold", code: 5, color: "darkorange" },
]

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
))

const Divider = styled(MuiDivider)(spacing)
// const Button = styled(MuiButton)(spacing)

class IDVMain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initResponse: {},
      step: 1,
      formData: {},
      loadingError: null,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.setState({ formData: {} }, this.getUrlToken)
  }

  getFormDataFromResponse = (response) => {
    const formData = {}
    if (_.isArray(response.data)) {
      if (_.isObject(response.data[1])) {
        const { firstname, lastname } = response.data[1]
        if (_.isString(firstname)) {
          formData.firstname = firstname
        }
        if (_.isString(lastname)) {
          formData.lastname = lastname
        }
      }

      if (_.isArray(response.data[2])) {
        const { 0: firstEle } = response.data[2]
        if (_.isObject(firstEle) && firstEle.address) {
          formData.email = firstEle.address
        }
      }
    }

    this.setState({ formData })
  }

  getUrlToken = async () => {
    const {
      match: {
        params: { uniqueIdvId },
      },
    } = this.props
    this.setState({ loadingError: null, isLoading: true })
    try {
      const url = `${process.env.REACT_APP_API_BASE_URI}idv/url/${uniqueIdvId}`
      const tenantHeaders = getTenantHeaders()
      const response = await Axios.get(url, {
        method: "GET",
        params: {
          request_user_id: localStorage.getItem("saffron-user-id"),
        },
        headers: tenantHeaders,
      })
      if (_.isArray(response.data)) {
        this.setState({
          initResponse: response.data[0],
          loadingError: null,
          isLoading: false,
        })
        this.getFormDataFromResponse(response)
      } else if (_.isObject(response.data) && response.data.token) {
        this.setState({
          initResponse: response.data,
          loadingError: null,
          isLoading: false,
        })
      }
      // console.log("response", response)
      // this.initialize()
    } catch (error) {
      console.log(error)
      if (error && error.response && error.response.status === 404) {
        console.log("getUrlToken", "Applicant does not exist")
        this.setState({ loadingError: error.response, isLoading: false })
      }
      // this.initialize()
    }
  }

  initialize = () => {
    const { initResponse } = this.state
    const { token, applicationLevel } = initResponse
    let flowName = "basic-kyc"

    if (applicationLevel) {
      flowName = applicationLevel
    }
    // hot fix basic-kyc flow
    if (flowName === "basic-kyc-level") {
      flowName = "basic-kyc"
    }

    console.log("IDV-RESPONSE:", initResponse)
    if (token) {
      this.launchWebSdk(process.env.REACT_APP_SUM_SUB_API, flowName, token)
    }
  }

  newAccessToken = () => {
    return "ACCESS_TOKEN"
  }

  onStepCompleted = (payload) => {
    console.log("Step Completed", payload)
  }

  onApplicantStatus = async (payload) => {
    // console.log("on Applicant Status", payload)
    const { initResponse } = this.state
    const { userId } = initResponse
    // const { firstname, lastname, email } = formData
    const key = payload.reviewStatus || "init"
    const sObj = _.find(statusMap, (i) => i.key === key)
    try {
      const reqdata = {
        external_id: userId,
        status_id: sObj?.code || 1,
        // first_name: firstname,
        // last_name: lastname,
        // email_address: email,
        request_user_id: localStorage.getItem("saffron-user-id"),
      }

      // const url = `${process.env.REACT_APP_API_BASE_URI}idv/application/url/status/`
      // const url = `${process.env.REACT_APP_API_BASE_URI}idv/application`
      const url = `${process.env.REACT_APP_API_BASE_URI}idv/application/status/${userId}`
      // const url = `${process.env.REACT_APP_API_BASE_URI}idv/application/${userId}`
      const tenantHeaders = getTenantHeaders()
      const response = await Axios.put(url, reqdata, { headers: tenantHeaders })
      const responseJson = response.data
      console.log("onApplicantStatus", responseJson)
      if (response.status === 200) {
        console.log("onApplicantStatus", responseJson)
      } else if (response.status === 409) {
        console.log("onApplicantStatus", "Apllicant already exist")
      }
    } catch (error) {
      if (error && error.response && error.response.status === 409) {
        console.log("onApplicantStatus", "Apllicant already exist")
      }
      console.log("onApplicantStatus", error)
    }
  }

  /**
 * @param apiUrl - 'https://test-api.sumsub.com' (sandbox)
                    or 'https://api.sumsub.com' (production)
 * @param flowName - the flow name chosen at Step 1 (e.g. 'basic-kyc')
 * @param accessToken - access token that you generated on the backend in Step 2
 * @param applicantEmail - applicant email (not required)
 * @param applicantPhone - applicant phone, if available (not required)
 * @param customI18nMessages - customized locale messages for current session (not required)
 */

  launchWebSdk = (
    apiUrl,
    flowName,
    accessToken,
    applicantEmail,
    applicantPhone,
    customI18nMessages
  ) => {
    const snsWebSdkInstance = snsWebSdk
      .Builder(apiUrl, flowName)
      .withAccessToken(accessToken, (newAccessTokenCallback) => {
        // Access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        const newAccessToken = this.newAccessToken() // get a new token from your backend
        newAccessTokenCallback(newAccessToken)
      })
      .withConf({
        lang: "en",
        email: applicantEmail,
        phone: applicantPhone,
        i18n: customI18nMessages,
        onMessage: (type, payload) => {
          // see below what kind of messages the WebSDK generates
          console.log("WebSDK onMessage", type, payload)

          // if (type === "stepCompleted") {
          //   // console.log("Step Completed")
          //   this.onStepCompleted(payload)
          // }

          if (type === "idCheck.applicantStatus") {
            // console.log("Step Completed")
            this.onApplicantStatus(payload)
          }

          if (type === "applicantStatus") {
            // console.log("Step Completed")
            this.onApplicantStatus(payload)
          }
        },
        uiConf: {
          // customCss: "https://url.com/styles.css",
          // URL to css file in case you need change it dynamically from the code
          // the similar setting at Applicant flow will rewrite customCss
          // you may also use to pass string with plain styles `customCssStr:`
        },
        onError: (error) => {
          console.error("WebSDK onError", error)
        },
      })
      .build()

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch("#sumsub-websdk-container")
  }

  renderBreadCrumbs = () => {
    const {
      match: { url },
    } = this.props
    // console.log("match", this.props.match)
    return (
      <Breadcrumbs
        separator=""
        aria-label="Breadcrumb"
        mt={10}
        style={{ display: "flex", listStyle: "none", alignItems: "center" }}
      >
        {/* <HomeBreadcrumb component={NavLink} to={{ pathname: "/" }}>
          <BreadcrumHomeIcon />
        </HomeBreadcrumb> */}

        <StyledBreadcrumb
          component={NavLink}
          to={{ pathname: url }}
          style={{
            backgroundColor: "#436EE0",
            clipPath: "polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%)",
          }}
        >
          ID Verification
        </StyledBreadcrumb>
      </Breadcrumbs>
    )
  }

  onSubmit = (data) => {
    this.setState({ step: 2, formData: data }, this.saveDataAndNext)
  }

  saveDataAndNext = async () => {
    const { initResponse, formData } = this.state
    const { userId } = initResponse
    const { firstname, lastname, email } = formData
    try {
      const reqdata = {
        first_name: firstname,
        last_name: lastname,
        email_address: email,
        request_user_id: localStorage.getItem("saffron-user-id"),
      }
      const url = `${process.env.REACT_APP_API_BASE_URI}idv/application/${userId}`
      const tenantHeaders = getTenantHeaders()
      const response = await Axios.put(url, reqdata, { headers: tenantHeaders })
      const responseJson = response.data
      console.log("saveDataAndNext", responseJson)
      if (response.status === 200) {
        // console.log("saveDataAndNext", responseJson)
        this.initialize()
      } else if (response.status === 409) {
        console.log("saveDataAndNext", "Apllicant already exist")
      }
    } catch (error) {
      if (error && error.response && error.response.status === 409) {
        // console.log("saveDataAndNext", "Apllicant already exist")
      }
      // console.log("onApplicantStatus", error)
    }
  }

  renderError = () => {
    // const { orgError } = this.props
    // console.log("renderError", orgError)
    return <NotFound />
  }

  renderLoader = () => {
    // const { sfSettingsLoad } = this.props
    return (
      <Box
        style={{
          width: "100%",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          // style={{ position: "absolute", left: "50%", top: "50%" }}
          m={2}
          color="primary"
        />
      </Box>
    )
  }

  render() {
    const { classes } = this.props
    const { step, formData, loadingError, isLoading } = this.state
    if (isLoading) {
      return this.renderLoader()
    }
    if (loadingError) {
      return this.renderError()
    }
    return (
      <div className={classes.root}>
        <Helmet title="Saffron IOS | ID Verification " />
        {/* <SaffronAlert
            open={open}
            close={this.handleClose}
            status={alertStatus}
            message={alertMessage}
            /> */}
        <div style={{ marginTop: "7px" }} />
        <div style={{ flexDirection: "row", display: "flex", width: "100%" }}>
          <Typography
            variant="h3"
            gutterBottom
            display="inline"
            style={{ width: 200, marginRight: 20 }}
          >
            Saffon IOS
          </Typography>

          <Typography variant="h4" gutterBottom display="inline">
            ID Verification
          </Typography>
        </div>
        <Divider mt={3} />
        <div style={{ paddingLeft: 220, backgroundColor: "#F9FAFC" }}>
          {this.renderBreadCrumbs()}
        </div>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.container}>
            {step === 1 ? (
              <BasicForm formData={formData} onSubmit={this.onSubmit} />
            ) : (
              <div id="sumsub-websdk-container" />
            )}
          </div>
        </main>
      </div>
    )
  }
}

IDVMain.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
}

IDVMain.defaultProps = {
  classes: {},
  match: {},
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    IDVMain
  )
)
