export const FETCH_ARTICLE_DETAILS = "FETCH_ARTICLE_DETAILS"
export const SET_ARTICLE_DETAILS = "SET_ARTICLE_DETAILS"

export const API = "API"
export const API_START = "API_START"
export const API_END = "API_END"
export const ACCESS_DENIED = "ACCESS_DENIED"
export const API_ERROR = "API_ERROR"
export const FETCH_ORG_LIST = "FETCH_ORG_LIST"
export const SET_ORG_LIST = "SET_ORG_LIST"
export const FETCH_ORG_TYPES = "FETCH_ORG_TYPES"
export const SET_ORG_TYPES = "SET_ORG_TYPES"
export const FETCH_COUNTRIES = "FETCH_COUNTRIES"
export const SET_COUNTRIES = "SET_COUNTRIES"
export const CREATE_ORG = "CREATE_ORG"
export const CREATE_ORG_RESPONSE = "CREATE_ORG_RESPONSE"
export const SET_OPEN = "SET_OPEN"
export const FETCH_ORG_DATA = "FETCH_ORG_DATA"
export const SET_ORG_DATA = "SET_ORG_DATA"
export const SET_ORG_ERROR = "SET_ORG_ERROR"
export const EDIT_ORG = "EDIT_ORG"
export const GET_ADDRESS_TYPES = "GET_ADDRESS_TYPES"
export const SET_ADDRESS_TYPES = "SET_ADDRESS_TYPES"
export const EDIT_ADDRESS = "EDIT_ADDRESS"
export const CREATE_ADDRESS = "CREATE_ADDRESS"
export const FETCH_AML_DATA = "FETCH_AML_DATA"
export const SET_AML_DATA = "SET_AML_DATA"
export const UPDATE_ORG_AML_SETTINGS = "UPDATE_ORG_AML_SETTINGS"
export const RUN_AML_CHECK = "RUN_AML_CHECK"
export const RUN_KYC_CHECK = "RUN_KYC_CHECK"
export const FETCH_CONTROLLER_LIST = "FETCH_CONTROLLER_LIST"
export const SET_CONTROLLER_LIST = "SET_CONTROLLER_LIST"
export const FETCH_CONTACT_LIST = "FETCH_CONTACT_LIST"
export const SET_CONTACT_LIST = "SET_CONTACT_LIST"
export const CREATE_PERSON = "CREATE_PERSON"
export const CREATE_PERSON_RESPONSE = "CREATE_PERSON_RESPONSE"
export const FETCH_PERSON_DATA = "FETCH_PERSON_DATA"
export const SET_PERSON_DATA = "SET_PERSON_DATA"
export const SET_PERSON_ERROR = "SET_PERSON_ERROR"
export const SET_PARENT_ORG_DATA = "SET_PARENT_ORG_DATA"
export const UPDATE_PERSON_AML_SETTINGS = "UPDATE_PERSON_AML_SETTINGS"
export const SET_IDENTIFIERS = "SET_IDENTIFIERS"
export const FETCH_IDENTIFIERS = "FETCH_IDENTIFIERS"
export const SET_DOCUMENT_TYPES = "SET_DOCUMENT_TYPES"
export const FETCH_DOCUMENT_TYPES = "FETCH_DOCUMENT_TYPES"
export const CREATE_DOCUMENT = "CREATE_DOCUMENT"
export const CREATE_DOCUMENT_RESPONSE = "CREATE_DOCUMENT_RESPONSE"
export const SET_DOCUMENTS = "SET_DOCUMENTS"
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS"
export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
export const SET_SEARCH_ENTITIES = "SET_SEARCH_ENTITIES"
export const FETCH_SEARCH_ENTITIES = "FETCH_SEARCH_ENTITIES"
export const FETCH_RELATE_ENTITY = "FETCH_RELATE_ENTITY"

export const FETCH_ORG_STATUS_LIST = "FETCH_ORG_STATUS_LIST"
export const SET_ORG_STATUS_LIST = "SET_ORG_STATUS_LIST"
export const FETCH_PERSON_STATUS_LIST = "FETCH_PERSON_STATUS_LIST"
export const SET_PERSON_STATUS_LIST = "SET_PERSON_STATUS_LIST"

export const FETCH_ROLES = "FETCH_ROLES"
export const SET_ROLES_LIST = "SET_ROLES_LIST"

export const SET_ORG_ENTITY_NAME = "SET_ORG_ENTITY_NAME"
export const SET_PERSON_ENTITY_NAME = "SET_PERSON_ENTITY_NAME"
export const REMOVE_RELATE_ENTITY = "REMOVE_RELATE_ENTITY"

export const FETCH_ORG_ROLES_LIST = "FETCH_ORG_ROLES_LIST"
export const SET_ORG_ROLES_LIST = "SET_ORG_ROLES_LIST"

export const FETCH_UPDATE_ROLE = "FETCH_UPDATE_ROLE"
export const FETCH_DELETE_ROLE = "FETCH_DELETE_ROLE"

export const FETCH_DOCUMENT_STATUS_LIST = "FETCH_DOCUMENT_STATUS_LIST"
export const SET_DOCUMENT_STATUS_LIST = "SET_DOCUMENT_STATUS_LIST"

export const UPDATE_DOCUMENT_REVISION_STATUS = "UPDATE_DOCUMENT_REVISION_STATUS"
export const FETCH_ORGANISATION_SEARCH = "FETCH_ORGANISATION_SEARCH"
export const SET_ORGANIZATION_SEARCH_RESPONSE =
  "SET_ORGANIZATION_SEARCH_RESPONSE"

export const FETCH_SF_SETTINGS = "FETCH_SF_SETTINGS"
export const SET_SF_SETTINGS = "SET_SF_SETTINGS"

export const FETCH_SF_ACCOUNTS = "FETCH_SF_ACCOUNTS"
export const SET_SF_ACCOUNTS = "SET_SF_ACCOUNTS"

export const SET_SALESFORCE_TAB = "SET_SALESFORCE_TAB"
export const RUN_SF_IMPORT = "RUN_SF_IMPORT"
export const RUN_SF_IMPORT_RESPONSE = "RUN_SF_IMPORT_RESPONSE"

export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAILED = "CREATE_USER_FAILED"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED"

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILED = "GET_USER_FAILED"

export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD"
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS"
export const RESET_USER_PASSWORD_FAILED = "RESET_USER_PASSWORD_FAILED"

export const GET_ROLES_LIST_FOR_USER = "GET_ROLES_LIST_FOR_USER"
export const SET_ROLES_LIST_FOR_USER = "SET_ROLES_LIST_FOR_USER"

export const GET_PROJECTS_LIST_FOR_USER = "GET_PROJECTS_LIST_FOR_USER"
export const SET_PROJECTS_LIST_FOR_USER = "SET_PROJECTS_LIST_FOR_USER"
export const GET_USER_AVAILABLE_PROJECTS = "GET_USER_AVAILABLE_PROJECTS"
export const SET_USER_AVAILABLE_PROJECTS = "SET_USER_AVAILABLE_PROJECTS"
export const ADD_PROJECT_ACCESS_TO_USER = "ADD_PROJECT_ACCESS_TO_USER"
export const DELETE_PROJECT_ACCESS_TO_USER = "DELETE_PROJECT_ACCESS_TO_USER"

export const GET_ROLES_LIST_FOR_USER_PROGRAMMES =
  "GET_ROLES_LIST_FOR_USER_PROGRAMMES"
export const SET_ROLES_LIST_FOR_USER_PROGRAMMES =
  "SET_ROLES_LIST_FOR_USER_PROGRAMMES"

export const GET_PROGRAMMES_LIST_FOR_USER = "GET_PROGRAMMES_LIST_FOR_USER"
export const SET_PROGRAMMES_LIST_FOR_USER = "SET_PROGRAMMES_LIST_FOR_USER"
export const GET_USER_AVAILABLE_PROGRAMMES = "GET_USER_AVAILABLE_PROGRAMMES"
export const SET_USER_AVAILABLE_PROGRAMMES = "SET_USER_AVAILABLE_PROGRAMMES"
export const ADD_PROGRAMME_ACCESS_TO_USER = "ADD_PROGRAMME_ACCESS_TO_USER"
export const DELETE_PROGRAMME_ACCESS_TO_USER = "DELETE_PROGRAMME_ACCESS_TO_USER"

export const SEND_IDV_APPLICATION_EMAIL = "SEND_IDV_APPLICATION_EMAIL"
export const SEND_IDV_APPLICATION_EMAIL_SUCCESS =
  "SEND_IDV_APPLICATION_EMAIL_SUCCESS"
export const SEND_IDV_APPLICATION_EMAIL_FAILED =
  "SEND_IDV_APPLICATION_EMAIL_FAILED"

export const GET_GLOBAL_DATA_FIELDS = "GET_GLOBAL_DATA_FIELDS"
export const GET_GLOBAL_DATA_FIELDS_SUCCESS = "GET_GLOBAL_DATA_FIELDS_SUCCESS"
export const GET_GLOBAL_DATA_FIELDS_FAILED = "GET_GLOBAL_DATA_FIELDS_FAILED"

export const ADD_GLOBAL_DATA_FIELD = "ADD_GLOBAL_DATA_FIELD"
export const ADD_GLOBAL_DATA_FIELD_SUCCESS = "ADD_GLOBAL_DATA_FIELD_SUCCESS"
export const ADD_GLOBAL_DATA_FIELD_FAILED = "ADD_GLOBAL_DATA_FIELD_FAILED"

export const EDIT_GLOBAL_DATA_FIELD = "EDIT_GLOBAL_DATA_FIELD"
export const EDIT_GLOBAL_DATA_FIELD_SUCCESS = "EDIT_GLOBAL_DATA_FIELD_SUCCESS"
export const EDIT_GLOBAL_DATA_FIELD_FAILED = "EDIT_GLOBAL_DATA_FIELD_FAILED"

export const DELETE_GLOBAL_DATA_FIELD = "DELETE_GLOBAL_DATA_FIELD"
export const DELETE_GLOBAL_DATA_FIELD_SUCCESS =
  "DELETE_GLOBAL_DATA_FIELD_SUCCESS"
export const DELETE_GLOBAL_DATA_FIELD_FAILED = "DELETE_GLOBAL_DATA_FIELD_FAILED"

export const SET_CURRENT_USER_NAME = "SET_CURRENT_USER_NAME"

export const GET_PERSON_APPLICATION_REPORT = "GET_PERSON_APPLICATION_REPORT"

export const SET_DOCUMENT = "SET_DOCUMENT"
export const GET_ORG_CASE_NOTES_LIST = "GET_ORG_CASE_NOTES_LIST"
export const SET_ORG_CASE_NOTES_LIST = "SET_ORG_CASE_NOTES_LIST"
export const CREATE_ORG_CASE_NOTE = "CREATE_ORG_CASE_NOTE"
export const UPDATE_ORG_CASE_NOTE = "UPDATE_ORG_CASE_NOTE"
export const DELETE_ORG_CASE_NOTE = "DELETE_ORG_CASE_NOTE"
