import _ from "lodash"
import { getErrorMessage } from "../common/utils"
import {
  FETCH_CONTROLLER_LIST,
  SET_CONTROLLER_LIST,
  RUN_AML_CHECK,
  UPDATE_ORG_AML_SETTINGS,
  SET_AML_DATA,
  FETCH_AML_DATA,
  CREATE_ADDRESS,
  FETCH_ORG_DATA,
  SET_ORG_DATA,
  SET_OPEN,
  EDIT_ORG,
  CREATE_ORG,
  SET_COUNTRIES,
  FETCH_COUNTRIES,
  SET_ORG_LIST,
  API,
  FETCH_ORG_LIST,
  FETCH_ORG_TYPES,
  SET_ORG_TYPES,
  SET_PARENT_ORG_DATA,
  FETCH_IDENTIFIERS,
  SET_IDENTIFIERS,
  FETCH_DOCUMENT_TYPES,
  SET_DOCUMENT_TYPES,
  CREATE_DOCUMENT,
  FETCH_DOCUMENTS,
  SET_DOCUMENTS,
  DELETE_DOCUMENT,
  FETCH_SEARCH_ENTITIES,
  SET_SEARCH_ENTITIES,
  FETCH_RELATE_ENTITY,
  FETCH_ORG_STATUS_LIST,
  SET_ORG_STATUS_LIST,
  REMOVE_RELATE_ENTITY,
  SET_ROLES_LIST,
  FETCH_ROLES,
  SET_ORG_ENTITY_NAME,
  FETCH_ORG_ROLES_LIST,
  SET_ORG_ROLES_LIST,
  FETCH_UPDATE_ROLE,
  FETCH_DELETE_ROLE,
  FETCH_DOCUMENT_STATUS_LIST,
  SET_DOCUMENT_STATUS_LIST,
  UPDATE_DOCUMENT_REVISION_STATUS,
  FETCH_ORGANISATION_SEARCH,
  SET_ORGANIZATION_SEARCH_RESPONSE,
  FETCH_SF_SETTINGS,
  SET_SF_SETTINGS,
  FETCH_SF_ACCOUNTS,
  SET_SF_ACCOUNTS,
  RUN_SF_IMPORT,
  RUN_SF_IMPORT_RESPONSE,
  SET_SALESFORCE_TAB,
  GET_ADDRESS_TYPES,
  SET_ADDRESS_TYPES,
  FETCH_CONTACT_LIST,
  SET_CONTACT_LIST,
  GET_ROLES_LIST_FOR_USER,
  SET_ROLES_LIST_FOR_USER,
  GET_PROJECTS_LIST_FOR_USER,
  SET_PROJECTS_LIST_FOR_USER,
  GET_USER_AVAILABLE_PROJECTS,
  SET_USER_AVAILABLE_PROJECTS,
  ADD_PROJECT_ACCESS_TO_USER,
  DELETE_PROJECT_ACCESS_TO_USER,
  SET_PROGRAMMES_LIST_FOR_USER,
  GET_PROGRAMMES_LIST_FOR_USER,
  SET_USER_AVAILABLE_PROGRAMMES,
  GET_USER_AVAILABLE_PROGRAMMES,
  ADD_PROGRAMME_ACCESS_TO_USER,
  DELETE_PROGRAMME_ACCESS_TO_USER,
  SET_ROLES_LIST_FOR_USER_PROGRAMMES,
  GET_ROLES_LIST_FOR_USER_PROGRAMMES,
  SET_ORG_ERROR,
  GET_PERSON_APPLICATION_REPORT,
  GET_ORG_CASE_NOTES_LIST,
  CREATE_ORG_CASE_NOTE,
  DELETE_ORG_CASE_NOTE,
  UPDATE_ORG_CASE_NOTE,
  SET_ORG_CASE_NOTES_LIST,
} from "./types"

export function setMessageOpen(data) {
  return {
    type: SET_OPEN,
    payload: data,
  }
}

export function handleError(error, defaultMessage = "") {
  let message = getErrorMessage(error)
  if (message === "") {
    message = defaultMessage
  }

  return setMessageOpen({
    open: true,
    message,
    error: true,
  })
}

export function setOrgEntityName(data) {
  return {
    type: SET_ORG_ENTITY_NAME,
    payload: data,
  }
}

function apiAction({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onSuccessMessage = () => {},
  onFailure = () => {},
  onUploadProgress = () => {},
  label = "",
  headersOverride = null,
  message = "",
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onSuccessMessage,
      onFailure,
      onUploadProgress,
      label,
      headersOverride,
      message,
    },
  }
}

function setIdentifiers(data) {
  return {
    type: SET_IDENTIFIERS,
    payload: data.data,
  }
}

/**/
function setCountries(data) {
  return {
    type: SET_COUNTRIES,
    payload: data.data,
  }
}

/*
Set Org Types
 */
function setOrgTypes(data) {
  return {
    type: SET_ORG_TYPES,
    payload: data.data,
  }
}

function setOrgStatusList(data) {
  return {
    type: SET_ORG_STATUS_LIST,
    payload: data.data,
  }
}

function setOrgList(data) {
  /* eslint-disable no-param-reassign */
  // eslint-disable-next-line array-callback-return
  data.data.map((org) => {
    org.lei = org.identifiers[0] ? org.identifiers[0].identifier : ""
    org.controllers = org.org_controllers_count + org.person_controllers_count
  })
  /* eslint-enable no-param-reassign */

  return {
    type: SET_ORG_LIST,
    payload: data.data,
  }
}

/**
 * Salesforce
 * */

export function setSalesforceTab(tab) {
  // eslint-disable-next-line no-console
  console.log(`set tab to ${tab}`)
  return {
    type: SET_SALESFORCE_TAB,
    payload: tab,
  }
}

function setSfSettings(data) {
  return {
    type: SET_SF_SETTINGS,
    payload: data,
  }
}

export function fetchSfSettings() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}salesforce/settings`,
    onSuccess: setSfSettings,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occurred loading SF Settings"),
    label: FETCH_SF_SETTINGS,
  })
}

function setSfAccounts(data) {
  return {
    type: SET_SF_ACCOUNTS,
    payload: data.data,
  }
}

export function fetchSfAccounts() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}salesforce/accounts`,
    onSuccess: setSfAccounts,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occurred loading SF Accounts"),
    label: FETCH_SF_ACCOUNTS,
  })
}

function setSfAccountImportResponse(data) {
  return {
    type: RUN_SF_IMPORT_RESPONSE,
    payload: data.data,
  }
}

export function runSfAccountImport() {
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}salesforce/account/import`,
    onSuccess: setSfAccountImportResponse,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occurred on SF Account Import"),
    label: RUN_SF_IMPORT,
  })
}

/*
Get Countries
 */
export function fetchCountries() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}countries`,
    onSuccess: setCountries,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occured loading countries"),
    label: FETCH_COUNTRIES,
  })
}

export function fetchIdentifiers() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}identifiers`,
    onSuccess: setIdentifiers,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occured loading identifiers"),
    label: FETCH_IDENTIFIERS,
  })
}

/*
Get Organisation Types
 */
export function fetchOrgTypes() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/type`,
    onSuccess: setOrgTypes,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occurred loading organisation types"),
    label: FETCH_ORG_TYPES,
  })
}

/*
Fetch organisations
 */
export function fetchOrgList() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/table`,
    onSuccess: setOrgList,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occurred loading organisations"),
    label: FETCH_ORG_LIST,
  })
}

/*
Fetch funds
 */
export function fetchFundList() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}funds/table`,
    onSuccess: setOrgList,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occurred loading fund organisations"),
    label: FETCH_ORG_LIST,
  })
}

function setAddressTypesList(data) {
  return {
    type: SET_ADDRESS_TYPES,
    payload: data,
  }
}

/*
Fetch Org address types
*/

export function fetchAddressTypesList() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}address/type`,
    onSuccess: setAddressTypesList,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occured loading address types"),
    label: GET_ADDRESS_TYPES,
  })
}

/*
Fetch org status list
 */
export function fetchOrgStatusList() {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/status/list`,
    onSuccess: setOrgStatusList,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occured loading organisation status"),
    label: FETCH_ORG_STATUS_LIST,
  })
}

// function randomIntFromInterval(min, max) {
//   // min and max included
//   return Math.floor(Math.random() * (max - min + 1) + min)
// }

export function setOpen(isOpen) {
  return {
    type: SET_OPEN,
    payload: isOpen,
  }
}

export function fetchControllerList(organisationId, adminContacts = false) {
  let url = `${process.env.REACT_APP_API_BASE_URI}organisation/controllers/${organisationId}`
  if (adminContacts) {
    url = `${process.env.REACT_APP_API_BASE_URI}organisation/admin-contacts/${organisationId}`
  }
  return apiAction({
    url,
    // eslint-disable-next-line no-use-before-define
    onSuccess: setControllerList,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occured loading organisations"),
    label: FETCH_CONTROLLER_LIST,
  })
}

export function setContactList(data) {
  // return {
  //   type: SET_CONTACT_LIST,
  //   payload: data.data,
  // }
  if (_.isArray(data.data.orgControllers)) {
    // eslint-disable-next-line no-param-reassign
    data.data.orgControllers = _.compact(data.data.orgControllers)
  }

  if (_.isArray(data.data.individualControllers)) {
    // eslint-disable-next-line no-param-reassign
    data.data.individualControllers = _.compact(data.data.individualControllers)
  }
  // eslint-disable-next-line array-callback-return
  data.data.orgControllers.map((org) => {
    if (org) {
      // eslint-disable-next-line no-param-reassign
      org.lei = org.identifiers[0] ? org.identifiers[0].identifier : ""
      // eslint-disable-next-line no-param-reassign
      org.controllers = org.org_controllers_count + org.person_controllers_count
    }
  })
  // eslint-disable-next-line array-callback-return
  data.data.individualControllers.map(
    // eslint-disable-next-line array-callback-return
    (person) => {
      if (person) {
        /* eslint-disable */
        person.email =
        _.isArray(person.business_emails) && person.business_emails.length
          ? person.business_emails[0]
            ? person.business_emails[0].address
            : ""
          : ""
        /* eslint-enable */
      }
    }
  )

  return {
    type: SET_CONTACT_LIST,
    payload: data.data,
  }
}

export function fetchContactListByOrg(organisationId) {
  const url = `${process.env.REACT_APP_API_BASE_URI}organisation/all-contacts/${organisationId}`
  return apiAction({
    url,
    // eslint-disable-next-line no-use-before-define
    onSuccess: setContactList,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error occured loading contacts"),
    label: FETCH_CONTACT_LIST,
  })
}

export function fetchSubFundList(organisationId) {
  const url = `${process.env.REACT_APP_API_BASE_URI}organisation/sub-funds/${organisationId}`
  return apiAction({
    url,
    // eslint-disable-next-line no-use-before-define
    onSuccess: setSubFundList,
    onFailure: () =>
      // eslint-disable-next-line no-console
      console.log("Error occurred loading sub fund organisations"),
    label: FETCH_CONTROLLER_LIST,
  })
}

function setSubFundList(data) {
  // eslint-disable-next-line array-callback-return
  data.data.orgControllers.map((org) => {
    // eslint-disable-next-line no-param-reassign
    org.lei = org.identifiers[0] ? org.identifiers[0].identifier : ""
    // eslint-disable-next-line no-param-reassign
    org.controllers = org.org_controllers_count + org.person_controllers_count
  })

  // eslint-disable-next-line no-param-reassign
  data.data.individualControllers = []
  return {
    type: SET_CONTROLLER_LIST,
    payload: data.data,
  }
}

export function fetchServicesList(organisationId) {
  const url = `${process.env.REACT_APP_API_BASE_URI}organisation/services/${organisationId}`
  // console.log("RUN_SERVICE_URL", url)
  return apiAction({
    url,
    // eslint-disable-next-line no-use-before-define
    onSuccess: setServicesList,
    onFailure: () =>
      // eslint-disable-next-line no-console
      console.log("Error occurred loading organisation services"),
    label: FETCH_CONTROLLER_LIST,
  })
}

function setServicesList(data) {
  // console.log(data)
  // eslint-disable-next-line array-callback-return
  data.data.orgControllers.map((org) => {
    // eslint-disable-next-line no-param-reassign
    org.lei = org.identifiers[0] ? org.identifiers[0].identifier : ""
    // eslint-disable-next-line no-param-reassign
    org.controllers = org.org_controllers_count + org.person_controllers_count
  })

  // eslint-disable-next-line no-param-reassign
  data.data.individualControllers = []
  return {
    type: SET_CONTROLLER_LIST,
    payload: data.data,
  }
}

function setControllerList(data) {
  if (_.isArray(data.data.orgControllers)) {
    // eslint-disable-next-line no-param-reassign
    data.data.orgControllers = _.compact(data.data.orgControllers)
  }

  if (_.isArray(data.data.individualControllers)) {
    // eslint-disable-next-line no-param-reassign
    data.data.individualControllers = _.compact(data.data.individualControllers)
  }
  // eslint-disable-next-line array-callback-return
  data.data.orgControllers.map((org) => {
    if (org) {
      // eslint-disable-next-line no-param-reassign
      org.lei = org.identifiers[0] ? org.identifiers[0].identifier : ""
      // eslint-disable-next-line no-param-reassign
      org.controllers = org.org_controllers_count + org.person_controllers_count
    }
  })
  // eslint-disable-next-line array-callback-return
  data.data.individualControllers.map(
    // eslint-disable-next-line array-callback-return
    (person) => {
      if (person) {
        /* eslint-disable */
        person.email =
        _.isArray(person.business_emails) && person.business_emails.length
          ? person.business_emails[0]
            ? person.business_emails[0].address
            : ""
          : ""
        /* eslint-enable */
      }
    }
  )

  return {
    type: SET_CONTROLLER_LIST,
    payload: data.data,
  }
}

export function fetchAmlData(organisationId) {
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}aml/check/${organisationId}`,
    // eslint-disable-next-line no-use-before-define
    onSuccess: setAmlData,
    // eslint-disable-next-line no-console
    onFailure: () => console.log("Error loading aml data for organisation"),
    label: FETCH_AML_DATA,
    message: {
      open: true,
      message: "Error Updating Organisation",
      error: true,
    },
  })
}

export function runAmlCheck(data) {
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}aml/check`,
    data,
    onSuccess: () => fetchAmlData(data.id),
    onSuccessMessage: () =>
      setMessageOpen({ open: true, message: "AML Check Completed" }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Running AML Check",
        error: true,
      }),
    label: RUN_AML_CHECK,
  })
}

export function getReport(data) {
  return apiAction({
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URI}idv/application/org/report/${data.applicationId}`,
    onSuccess: (res) => {
      const type = "application/pdf"
      const blob = new Blob([res], { type, encoding: "UTF-8" })
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = `saffron-hub-report-${data.legalName}-${data.applicationId}.pdf`
      link.click()
    },
    onSuccessMessage: () => {
      return setMessageOpen({
        open: true,
        message: "Report downloaded successfully",
      })
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Getting report",
        error: true,
      }),
    label: GET_PERSON_APPLICATION_REPORT,
  })
}

function setCaseNotes(data) {
  return {
    type: SET_ORG_CASE_NOTES_LIST,
    payload: data.data || [],
  }
}

export function getOrgCaseNotes(data) {
  const entityId = data.entity_id
  delete data.entity_id
  console.log("getOrgCaseNotes entityId", entityId)
  return apiAction({
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URI}aml/case-notes/${entityId}`,
    onSuccess: setCaseNotes,
    onSuccessMessage: () => {
      // return setMessageOpen({
      //   open: true,
      //   message: "Report downloaded successfully",
      // })
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error getting case notes",
        error: true,
      }),
    label: GET_ORG_CASE_NOTES_LIST,
  })
}

export function createOrgCaseNotes(
  notesData,
  success = () => {},
  fail = () => {}
) {
  const entityId = notesData.entity_id
  // delete notesData.entity_id
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}aml/case-notes`,
    data: notesData,
    onSuccess: () => {
      return getOrgCaseNotes({ entity_id: entityId })
    },
    onSuccessMessage: () => {
      success()
      return setMessageOpen({
        open: true,
        message: "Case note created successfully!",
      })
    },
    onFailure: () => {
      fail()
      return setMessageOpen({
        open: true,
        message: "Error creating case note",
        error: true,
      })
    },
    label: CREATE_ORG_CASE_NOTE,
  })
}

export function updateOrgCaseNote(
  notesData,
  success = () => {},
  fail = () => {}
) {
  const ID = notesData.id
  const entityId = notesData.entity_id
  // delete notesData.entity_id
  delete notesData.id
  return apiAction({
    method: "PUT",
    url: `${process.env.REACT_APP_API_BASE_URI}aml/case-notes/${ID}`,
    data: notesData,
    onSuccess: () => {
      // console.log("updateOrgCaseNote", res.data)
      return getOrgCaseNotes({ entity_id: entityId })
    },
    onSuccessMessage: () => {
      success()
      return setMessageOpen({
        open: true,
        message: "Case note updated successfully!",
      })
    },
    onFailure: () => {
      fail()
      return setMessageOpen({
        open: true,
        message: "Error updating case note",
        error: true,
      })
    },
    label: UPDATE_ORG_CASE_NOTE,
  })
}

export function deleteOrgCaseNote(notesData) {
  const entityId = notesData.entity_id
  // delete notesData.entity_id
  return apiAction({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_BASE_URI}aml/case-notes/${notesData.id}`,
    // data: notesData,
    onSuccess: () => {
      // console.log("deleteOrgCaseNote", res.data)
      return getOrgCaseNotes({ entity_id: entityId })
    },
    onSuccessMessage: () => {
      return setMessageOpen({
        open: true,
        message: "Case note deleted successfully!",
      })
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error deleting case note",
        error: true,
      }),
    label: DELETE_ORG_CASE_NOTE,
  })
}

function setAmlData(data) {
  return {
    type: SET_AML_DATA,
    payload: data.data,
  }
}

export function setOrgError(error) {
  // console.log("setOrgError", error)
  // console.log("Error loading organisation")

  let payload = null
  if (error.response) {
    // console.log("setOrgError", error.response)
    payload = error.response
  }
  return {
    type: SET_ORG_ERROR,
    payload,
  }
}

export function fetchOrgData(organisationId, parent = false) {
  // eslint-disable-next-line no-use-before-define
  let successAction = setOrgData
  if (parent) {
    // eslint-disable-next-line no-use-before-define
    successAction = setParentOrgData
  }
  return apiAction({
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/${organisationId}`,
    onSuccess: successAction,
    // eslint-disable-next-line no-console
    onFailure: setOrgError,
    label: FETCH_ORG_DATA,
  })
}

export function setOrgData(data) {
  // console.log("SET_ORG_DATA payload", data.data)
  return {
    type: SET_ORG_DATA,
    payload: data.data,
  }
}

export function setParentOrgData(data) {
  return {
    type: SET_PARENT_ORG_DATA,
    payload: data.data,
  }
}

export function createOrg(orgData, success = () => {}, fail = () => {}) {
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}organisation`,
    data: orgData, // JSON.stringify(data),
    onSuccessMessage: () => {
      // console.log("onSuccessMessage")
      return setMessageOpen({
        open: true,
        message: "Entity Created",
        // data: data.data,
      })
    },
    onSuccess: (data) => {
      // console.log("createOrg", data)
      success(data.data)
      return setMessageOpen({
        open: true,
        message: "Entity Created",
        data: data.data,
      })
    },
    onFailure: (error) => {
      fail(error)
      const message = "Error Creating Entity"
      return handleError(error, message)
    },
    label: CREATE_ORG,
  })
}

export function updateOrgAmlSettings(data) {
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/aml/settings/${data.organisationId}`,
    data,
    onSuccess: () =>
      setMessageOpen({
        open: true,
        message: "Organisation AML Settings updated",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Updating Organisation AML Settings",
        error: true,
      }),
    label: UPDATE_ORG_AML_SETTINGS,
  })
}

export function editOrg(data) {
  return apiAction({
    method: "PUT",
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/${data.id}`,
    data,
    onSuccess: (res) =>
      setMessageOpen({
        open: true,
        message: "Organisation updated",
        data: res.data,
      }),
    onFailure: (error) => {
      const message = "Error Updating Organisation"
      return handleError(error, message)
      // return setMessageOpen({
      //   open: true,
      //   message: "Error Updating Organisation",
      //   error: true,
      // })
    },
    label: EDIT_ORG,
  })
}

export function createAddress(data) {
  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/address`,
    data,
    onSuccess: () => setMessageOpen({ open: true, message: "Address created" }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Creating Address",
        error: true,
      }),
    label: CREATE_ADDRESS,
  })
}

export function editAddress(data) {
  return apiAction({
    method: "PUT",
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/address/${data.id}`,
    data,
    onSuccess: () => setMessageOpen({ open: true, message: "Address updated" }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Updating Address",
        error: true,
      }),
    label: CREATE_ADDRESS,
  })
}

export function deleteAddress(data) {
  return apiAction({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/address/${data.id}`,
    data,
    onSuccess: () => {
      setMessageOpen({ open: true, message: "Address deleted" })
      // fetchOrgData(data.organisationId)
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error deleting address",
        error: true,
      }),
    label: CREATE_ADDRESS,
  })
}

// function setResponseError(error) {
//   console.log(error.message)
// }

function setDocumentTypes(data) {
  return {
    type: SET_DOCUMENT_TYPES,
    payload: data.data,
  }
}

export function getDocumentTypesList() {
  return apiAction({
    method: "GET",
    // url: `${process.env.REACT_APP_API_BASE_URI}document/type-list`, // TODO change api endpoint after API deploy
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}document/type-list`,
    onSuccess: setDocumentTypes,
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error loading document types",
        error: true,
      }),
    label: FETCH_DOCUMENT_TYPES,
  })
}

function setDocuments(data) {
  console.log("setDocuments", data)
  return {
    type: SET_DOCUMENTS,
    payload: data.data,
  }
}

export function getDocuments(documentData) {
  let url = `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}document`
  if (documentData.organisation_id) {
    url += `/organisation/${documentData.organisation_id}`
  }

  if (documentData.person_id) {
    url += `/person/${documentData.person_id}`
  }

  return apiAction({
    method: "GET",
    url,
    onSuccess: setDocuments,
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error Getting Documents",
        error: true,
      }),
    label: FETCH_DOCUMENTS,
  })
}

export function createDocument(
  documentData,
  onUploadProgress = () => {},
  onSuccess = () => {}
) {
  const formData = new FormData()

  // eslint-disable-next-line array-callback-return
  Object.keys(documentData).map((key) => {
    formData.append(key, documentData[key])
  })

  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}document`,
    data: formData,
    headers: {
      Accept: "*/*",
      "Content-Type": `multipart/form-data`,
    },
    onUploadProgress,
    onSuccess: () => {
      return getDocuments(documentData)
    },
    onSuccessMessage: () => {
      onSuccess()
      return setMessageOpen({ open: true, message: "Document Uploaded" })
    },
    onFailure: (error) => {
      const message = "Error Uploading Document"
      return handleError(error, message)
      // return setMessageOpen({
      //   open: true,
      //   message: "Error Uploading Document",
      //   error: true,
      // })
    },
    label: CREATE_DOCUMENT,
  })
}

export function deleteDocument(documentData) {
  return apiAction({
    method: "DELETE",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}document/${documentData.id}`,
    data: documentData,
    onSuccess: () => getDocuments(documentData),
    onSuccessMessage: (data) =>
      setMessageOpen({
        open: true,
        message: "Document deleted successfully",
        data: data.data,
      }),
    onFailure: (error) => {
      const message = "Error deleting document"
      return handleError(error, message)
      // return setMessageOpen({
      //   open: true,
      //   message: "Error deleting document",
      //   error: true,
      // })
    },
    label: DELETE_DOCUMENT,
  })
}

// export function fetchRolesByType(roleTypeId) {
//   const url = `${process.env.REACT_APP_API_BASE_URI}roles/type/${roleTypeId}`
//   return apiAction({
//     url,
//     // eslint-disable-next-line no-use-before-define
//     onSuccess: setRolesList,
//     onFailure: () =>
//       // eslint-disable-next-line no-console
//       console.log("Error occurred loading roles"),
//     label: FETCH_ROLES,
//   })
// }

export function fetchRolesByType(roleTypeIds = [], apiType = "") {
  let type = ""
  if (apiType === "person") {
    type = "person"
  } else if (apiType === "organisation") {
    type = "org"
  }

  // console.log("fetchRolesByType apiType", apiType)
  const url = `${process.env.REACT_APP_API_BASE_URI}roles/${type}/type`
  return apiAction({
    url,
    method: "POST",
    data: {
      type_ids: roleTypeIds,
    },
    // eslint-disable-next-line no-use-before-define
    onSuccess: setRolesList,
    onFailure: () =>
      // eslint-disable-next-line no-console
      console.log("Error occurred loading roles"),
    label: FETCH_ROLES,
  })
}

export function fetchRolesByPerson(personId = null) {
  const url = `${process.env.REACT_APP_API_BASE_URI}person/roles/${
    personId === null ? "all" : personId
  }`
  return apiAction({
    url,
    // eslint-disable-next-line no-use-before-define
    onSuccess: setRolesList,
    onFailure: () =>
      // eslint-disable-next-line no-console
      console.log("Error occurred loading roles"),
    label: FETCH_ROLES,
  })
}

export function setRolesList(data) {
  return {
    type: SET_ROLES_LIST,
    payload: data.data,
  }
}

export function setSearchEntities(data) {
  return {
    type: SET_SEARCH_ENTITIES,
    payload: data.data,
  }
}

export function getSearchEntities(
  reqdata,
  apiType = "organisation",
  section = ""
) {
  let searchTerm = "search"
  if (section === "subfund") {
    searchTerm = "search-funds"
  }
  return apiAction({
    method: "GET",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}${apiType}/${searchTerm}/${reqdata.search}`,
    onSuccess: setSearchEntities,
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in searching entities",
        error: true,
      }),
    label: FETCH_SEARCH_ENTITIES,
  })
}

export function removeRelationship(reqdata, relationshipType = "false") {
  let functionName = ""
  if (relationshipType === "false") {
    functionName = () =>
      fetchControllerList(reqdata.selectedEntity.organisation_id)
  } else if (relationshipType === "service") {
    functionName = () =>
      fetchServicesList(reqdata.selectedEntity.related_organisation_id)
  } else if (relationshipType === "subfund") {
    functionName = () =>
      fetchSubFundList(reqdata.selectedEntity.related_organisation_id)
  }

  return apiAction({
    method: "DELETE",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}organisation/relationship/${reqdata.selectedEntity.role_relationship_id}`,
    onSuccess: functionName,
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "Entity relationship removed",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in removing entity relationship",
        error: true,
      }),
    label: REMOVE_RELATE_ENTITY,
  })
}

export function relateEntity(
  reqdata,
  relationshipType = "false",
  success = () => {}
) {
  /* 
  /api/v1/organisation/relate
  */
  let functionName = ""

  if (relationshipType === "false") {
    functionName = () => fetchControllerList(reqdata.organisation_id)
  } else if (relationshipType === "service") {
    functionName = () => fetchServicesList(reqdata.related_organisation_id)
  } else if (relationshipType === "subfund") {
    functionName = () => fetchSubFundList(reqdata.related_organisation_id)
  }

  return apiAction({
    method: "POST",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}organisation/relate`,
    data: reqdata,
    onSuccess: () => {
      success()
      functionName()
    },
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "Entity relation added successfully",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in relating entity",
        error: true,
      }),
    label: FETCH_RELATE_ENTITY,
  })
}

export function setOrgRolesList(data) {
  // eslint-disable-next-line no-console
  console.log("setOrgRolesList", data)
  return {
    type: SET_ORG_ROLES_LIST,
    payload: data || [],
  }
}

export function getOrgRolesList(reqdata, apiType = "organisation") {
  return apiAction({
    method: "GET",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}${apiType}/roles/${reqdata.id}`,
    onSuccess: (data) => {
      // eslint-disable-next-line no-console
      console.log("onSuccess", data)
      return setOrgRolesList(data)
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in getting organisation roles",
        error: true,
      }),
    label: FETCH_ORG_ROLES_LIST,
  })
}

export function updateRole(reqdata, apiType = "organisation") {
  let id = ""
  if (apiType === "organisation") {
    id = reqdata.id || ""
  } else if (apiType === "person") {
    id = reqdata.id || ""
  }
  // eslint-disable-next-line no-param-reassign
  delete reqdata.id
  return apiAction({
    method: "PUT",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}${apiType}/role/${id}`,
    data: reqdata,
    onSuccess: (data) => {
      // console.log("onSuccess updateRole", data)
      if (apiType === "organisation") {
        // return getOrgRolesList({ id: data.data.organisation_id })
        return fetchContactListByOrg(data.data.organisation_id)
      }
      if (apiType === "person") {
        return fetchContactListByOrg(data.data.organisation_id)
        // return getOrgRolesList({ id: data.data.person_id })
      }
      return true
    },
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "Entity relation modified successfully",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in updating entity relation",
        error: true,
      }),
    label: FETCH_UPDATE_ROLE,
  })
}

export function deleteRole(reqdata, apiType = "organisation") {
  let id = ""
  if (apiType === "organisation") {
    id = reqdata.id || ""
  } else if (apiType === "person") {
    id = reqdata.id || ""
  }
  // eslint-disable-next-line no-param-reassign
  delete reqdata.id
  return apiAction({
    method: "DELETE",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}${apiType}/role/${id}`,
    data: reqdata,
    onSuccess: (data) => {
      // console.log("onSuccess updateRole", data)
      if (apiType === "organisation") {
        // return getOrgRolesList({ id: data.data.organisation_id })
        return fetchContactListByOrg(data.data.organisation_id)
      }
      if (apiType === "person") {
        // return getOrgRolesList({ id: data.data.person_id })
        return fetchContactListByOrg(data.data.organisation_id)
      }
      return true
    },
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "Entity relation deleted successfully",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in deleting entity relation",
        error: true,
      }),
    label: FETCH_DELETE_ROLE,
  })
}

export function setDocumentStatusList(data) {
  // console.log("setDocumentStatusList", data)
  return {
    type: SET_DOCUMENT_STATUS_LIST,
    payload: data.data || [],
  }
}

export function getDocumentStatusList() {
  /*
   getDocumentStatusList: `${process.env.REACT_APP_DOC_API_BASEURL}document/status/list`,
    updateDocumentStatus: `${process.env.REACT_APP_DOC_API_BASEURL}document/revision/status`,
  */
  return apiAction({
    method: "GET",
    url: `${process.env.REACT_APP_DOC_API_BASEURL}document/status/list`,
    onSuccess: (data) => {
      // eslint-disable-next-line no-console
      console.log("onSuccess", data)
      return setDocumentStatusList(data)
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in getting document status list",
        error: true,
      }),
    label: FETCH_DOCUMENT_STATUS_LIST,
  })
}

export function updateDocumentStatus(reqdata, documentData) {
  return apiAction({
    method: "PUT",
    data: reqdata,
    url: `${process.env.REACT_APP_DOC_API_BASEURL}document/revision/status`,
    onSuccess: (data) => {
      // eslint-disable-next-line no-console
      // console.log("onSuccess", data)
      console.log("documentData", documentData)
      return getDocuments(documentData)
    },
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "Document status updated successfully",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in updating document status",
        error: true,
      }),
    label: UPDATE_DOCUMENT_REVISION_STATUS,
  })
}

function setOrgSearchResponse(data) {
  return {
    type: SET_ORGANIZATION_SEARCH_RESPONSE,
    payload: data,
  }
}

export function validateOrgName(str) {
  return apiAction({
    method: "GET",
    // data: reqdata,
    url: `${process.env.REACT_APP_API_BASE_URI}organisation/search/${str}`,
    onSuccess: (data) => {
      // eslint-disable-next-line no-console
      console.log("onSuccess", data)
      // return getDocuments(documentData)
      return setOrgSearchResponse(data)
    },
    onSuccessMessage: () => {
      // console.log("onSuccessMessage", data)
      // return setMessageOpen({
      //   open: true,
      //   message: "Document status updated successfully",
      // })
    },
    onFailure: (error) => {
      // eslint-disable-next-line no-console
      console.log("onFailure", error)
      return setOrgSearchResponse(error)
      // return setMessageOpen({
      //   open: true,
      //   message: "Error in updating document status",
      //   error: true,
      // })
    },
    label: FETCH_ORGANISATION_SEARCH,
  })
}

// function setCreateDocumentResponse(data) {
//   return {
//     type: CREATE_DOCUMENT_RESPONSE,
//     payload: data.data,
//   }
// }

function setUserAvailableProjects(data) {
  return {
    type: SET_USER_AVAILABLE_PROJECTS,
    payload: data.data,
  }
}

export function getUserAvailableProjects(userId) {
  return apiAction({
    method: "GET",
    // data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}users/available/projects/${userId}`,
    onSuccess: setUserAvailableProjects,
    onSuccessMessage: () => {
      // console.log("onSuccessMessage", data)
      // return setMessageOpen({
      //   open: true,
      //   message: "Document status updated successfully",
      // })
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in getting available projects list for user",
        error: true,
      }),
    label: GET_USER_AVAILABLE_PROJECTS,
  })
}

function setRolesListForUser(data) {
  return {
    type: SET_ROLES_LIST_FOR_USER,
    payload: data.data,
  }
}

export function getRolesListForUser() {
  return apiAction({
    method: "GET",
    // data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}users/projects/access-levels`,
    onSuccess: setRolesListForUser,
    onSuccessMessage: () => {
      // return setMessageOpen({
      //   open: true,
      //   message: "Error in getting organisation roles",
      //   data: data.data
      // }),
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in getting roles list for user",
        error: true,
      }),
    label: GET_ROLES_LIST_FOR_USER,
  })
}

function setProjectsListForUser(data) {
  return {
    type: SET_PROJECTS_LIST_FOR_USER,
    payload: data.data,
  }
}

export function getProjectsListForUser(reqdata) {
  return apiAction({
    method: "POST",
    data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}projects/get-not-in-list`,
    onSuccess: setProjectsListForUser,
    onSuccessMessage: () => {
      // console.log("onSuccessMessage", data)
      // return setMessageOpen({
      //   open: true,
      //   message: "Document status updated successfully",
      // })
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in getting projects list for user",
        error: true,
      }),
    label: GET_PROJECTS_LIST_FOR_USER,
  })
}

export function addProjectAccessToUser(reqdata) {
  return apiAction({
    method: "POST",
    data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}projects/access`,
    onSuccess: () => getUserAvailableProjects(reqdata.user_id),
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "User access granted successfully",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in granting project access to user",
        error: true,
      }),
    label: ADD_PROJECT_ACCESS_TO_USER,
  })
}

export function deleteProjectAccessToUser(accessId, userId) {
  // /projects/access/{id}
  return apiAction({
    method: "DELETE",
    // data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}projects/access/${accessId}`,
    onSuccess: () => getUserAvailableProjects(userId),
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "User access deleted successfully",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in delete project access",
        error: true,
      }),
    label: DELETE_PROJECT_ACCESS_TO_USER,
  })
}

function setUserAvailableProgrammes(data) {
  return {
    type: SET_USER_AVAILABLE_PROGRAMMES,
    payload: data.data,
  }
}

export function getUserAvailableProgrammes(userId) {
  return apiAction({
    method: "GET",
    // data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}users/available/programmes/${userId}`,
    onSuccess: setUserAvailableProgrammes,
    onSuccessMessage: () => {
      // console.log("onSuccessMessage", data)
      // return setMessageOpen({
      //   open: true,
      //   message: "Document status updated successfully",
      // })
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in getting available programmes list for user",
        error: true,
      }),
    label: GET_USER_AVAILABLE_PROGRAMMES,
  })
}

function setRolesListForUserProgrammes(data) {
  return {
    type: SET_ROLES_LIST_FOR_USER_PROGRAMMES,
    payload: data.data,
  }
}

export function getRolesListForUserProgrammes() {
  return apiAction({
    method: "GET",
    // data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}users/programmes/access-levels`,
    onSuccess: setRolesListForUserProgrammes,
    onSuccessMessage: () => {
      // return setMessageOpen({
      //   open: true,
      //   message: "Error in getting organisation roles",
      //   data: data.data
      // }),
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in getting roles list for user programmes",
        error: true,
      }),
    label: GET_ROLES_LIST_FOR_USER_PROGRAMMES,
  })
}

function setProgrammesListForUser(data) {
  // console.log("setProgrammesListForUser", data)
  return {
    type: SET_PROGRAMMES_LIST_FOR_USER,
    payload: data.data,
  }
}

export function getProgrammesListForUser(reqdata) {
  return apiAction({
    method: "POST",
    data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}programme/get-not-in-list`,
    onSuccess: setProgrammesListForUser,
    onSuccessMessage: () => {
      // console.log("onSuccessMessage", data)
      // return setMessageOpen({
      //   open: true,
      //   message: "Document status updated successfully",
      // })
    },
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in getting programmes list for user",
        error: true,
      }),
    label: GET_PROGRAMMES_LIST_FOR_USER,
  })
}

export function addProgrammeAccessToUser(reqdata) {
  return apiAction({
    method: "POST",
    data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}programmes/access`,
    onSuccess: () => getUserAvailableProgrammes(reqdata.user_id),
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "User access granted successfully",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in granting project access to user",
        error: true,
      }),
    label: ADD_PROGRAMME_ACCESS_TO_USER,
  })
}

export function deleteProgrammeAccessToUser(accessId, userId) {
  // /projects/access/{id}
  return apiAction({
    method: "DELETE",
    // data: reqdata,
    url: `${process.env.REACT_APP_PMS_API_SERVICE}programmes/access/${accessId}`,
    onSuccess: () => getUserAvailableProgrammes(userId),
    onSuccessMessage: () =>
      setMessageOpen({
        open: true,
        message: "User access deleted successfully",
      }),
    onFailure: () =>
      setMessageOpen({
        open: true,
        message: "Error in delete project access",
        error: true,
      }),
    label: DELETE_PROGRAMME_ACCESS_TO_USER,
  })
}
