import React, { useState } from "react"
import styled from "styled-components"
import { rgba, darken } from "polished"

import {
  NavLink as RouterNavLink,
  withRouter,
  Link as MainLink,
} from "react-router-dom"

import PerfectScrollbar from "react-perfect-scrollbar"
import "../vendor/perfect-scrollbar.css"

import {
  Avatar,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Divider,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"

import { ExpandMore, KeyboardArrowRight } from "@material-ui/icons"
import { connect } from "react-redux"
import PoweredBy from "./PoweredBy/PoweredBy"
import ZendDesk from "./ZendDesk/ZendDesk"

import routes from "../routes/index"

import { useAuth0 } from "../common/react-auth0-spa"

import { setOrgEntityName } from "../actions/organisation"
import { setPersonEntityName } from "../actions/person"
import { getTenantData } from "../common/utils"

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
))

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`

/*
svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 30px;
    width: 30px;
    height: 30px;
    opacity: 0.5;
  }
*/

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  

  transition: all .2s ease-in-out;
  transition: background .2s ease-in-out;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    //background: #FF9600;
    transition: background .2s ease-in-out;

  }

  &.${(props) => props.activeClassName} {
    //background-color: ${(props) =>
      darken(0.05, props.theme.sidebar.background)};

    span {
      //color: #FF9600;//${(props) => props.theme.sidebar.color};
    }
  }
`

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`
const CategoryLogoText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(1)}px;
  }
`

/*
const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`; */

const CategoryIconLess = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

const CategoryIconMore = styled(KeyboardArrowRight)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  color: #A5A5A5;
  &:hover {
   // background: rgba(0, 0, 0, 0.08);
  }

  span {
    font-size:12px !important;
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }
  
  &:hover span {
    color: #FF9600; //${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props) => props.activeClassName} {
    //background-color: ${(props) =>
      darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

const LLink = styled(MainLink)`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  
  &:hover {
    color: #FF9600; 
  }

 
  color: #A5A5A5;
  
  text-decoration: none;
  span {
    //color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
    
    color: #A5A5A5;
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props) => props.activeClassName} {
   // background-color: ${(props) =>
     darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

// TODO - Add marging left to theme
const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 20px;
`

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  //background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(4)}px;
  opacity: 0.9;
  display: block;
`

const LogoSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.header.logo.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(6)}px ${(props) =>
  props.theme.spacing(4)}px;
  opacity: 0.9;
  display: block;
  //margin-left: ${(props) => props.theme.spacing(10)}px;
`

const AvatarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(8)}px
    ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(8)}px;
  opacity: 0.9;
  display: block;
`

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`

const StyledAvatar = styled(Avatar)`
  color: ${(props) => props.theme.sidebar.avatar.color};
  background-color: ${(props) => props.theme.sidebar.avatar.background};
  margin-right: ${(props) => props.theme.spacing(2)}px;
  //margin-left: 0;//${(props) => props.theme.spacing(4)}px;
`

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  return (
    <Category {...rest}>
      {icon}
      {name === "Import" ? (
        <CategoryLogoText>{name}</CategoryLogoText>
      ) : (
        <CategoryText>{name}</CategoryText>
      )}
      {/* eslint-disable-next-line no-nested-ternary */}
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  )
}

function SidebarLinkF({
  name,
  to,
  badge,
  history,
  setOrgEntityName: setOrgName,
  setPersonEntityName: setPersonName,
}) {
  let onClick
  if (to === "/add-corporate-entity") {
    onClick = () => {
      setOrgName("")
      setPersonName("")
      history.push(to)
    }
  }
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      onClick={onClick}
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  )
}

const mapStateToProps = (state) => ({
  orgData: state.orgReducer.orgData,
})

const mapDispatchToProps = {
  setOrgEntityName,
  setPersonEntityName,
}

const SidebarLink = withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    SidebarLinkF
  )
)

function GetAcronym(name) {
  const str = String(name.string)
  const acronym = str
    .split(/\s/)
    // eslint-disable-next-line
    .reduce((response, word) => (response += word.slice(0, 1)), "")
  return acronym
}

function MainSidebar({ classes, staticContext, location, ...rest }) {
  const tenantData = getTenantData()
  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname

    let _routes = {}

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0
      const isOpen = route.open
      const isHome = !!(route.containsHome && pathName === "/")

      _routes = { ..._routes, [index]: isActive || isOpen || isHome }
    })

    return _routes
  }

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes())

  const toggle = (index) => {
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) => ({ ...openRoutes, [item]: false }))
    )

    setOpenRoutes((openRoutes) => ({
      ...openRoutes,
      [index]: !openRoutes[index],
    }))
  }

  const { isAuthenticated, logout } = useAuth0()
  const { loading, user } = useAuth0()

  if (loading || !user) {
    return null
  }

  function SAlogout(e) {
    e.preventDefault()
    let tenantDomain = ""
    if (tenantData) {
      tenantDomain = `${tenantData.domain}.`

      if (tenantDomain === "localhost.") {
        tenantDomain = ""
      }
    }

    const logoutUrl = `${process.env.REACT_APP_BASE}${tenantDomain}${process.env.REACT_APP_URI}`
    localStorage.setItem("tenantData", "")
    logout({ returnTo: logoutUrl })
  }

  let tenantName = ""

  // Set tenant base url
  // const tenantData = localStorage.getItem("tenantData")

  let tenantDomain = ""
  if (tenantData !== null) {
    tenantDomain = `${tenantData.domain}.`
    tenantName = tenantData.client_name
    if (tenantDomain === "localhost.") {
      tenantDomain = ""
    }
  }

  const tenantBaseUrl = `${process.env.REACT_APP_BASE}${tenantDomain}${process.env.REACT_APP_URI}`
  localStorage.setItem("tenantBaseUrl", tenantBaseUrl)

  return (
    <Drawer variant="permanent" {...rest}>
      <Scrollbar>
        <LogoSection component="span">
          <Grid container spacing={6}>
            <Grid item style={{ margin: "auto" }}>
              <Typography
                variant="h4"
                component="span"
                gutterBottom
                display="inline"
              >
                Saffron IOS
              </Typography>
            </Grid>
          </Grid>
        </LogoSection>
        <Divider />
        <Grid container spacing={6}>
          <Grid item style={{ margin: "auto" }}>
            <SidebarFooterText
              variant="body2"
              style={{ fontWeight: "bold", marginTop: 10, marginBottom: 10 }}
            >
              {tenantName}
            </SidebarFooterText>
          </Grid>
        </Grid>
        <Divider />
        <AvatarSection component="span">
          <Grid container spacing={6}>
            <Grid item style={{ margin: "auto" }}>
              <StyledAvatar style={{ margin: "auto" }} component="span">
                <GetAcronym string={user.name} />
              </StyledAvatar>
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            <Grid item style={{ margin: "auto" }}>
              <SidebarFooterText variant="body2">
                {isAuthenticated && user.name}
              </SidebarFooterText>
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            <Grid item style={{ margin: "auto" }}>
              {isAuthenticated && (
                <LLink
                  to="#"
                  pathname="#"
                  style={{ margin: "0", paddingLeft: "0", paddingRight: "0" }}
                  onClick={SAlogout}
                >
                  Log Out
                </LLink>
              )}
            </Grid>
          </Grid>
        </AvatarSection>
        <Divider />
        <List disablePadding>
          <Items>
            {routes.map((category, index) => (
              <React.Fragment key={String(index)}>
                {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children ? (
                  <React.Fragment key={String(index)}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable
                      name={category.id}
                      icon={category.icon}
                      button
                      onClick={() => toggle(index)}
                      to={category.path}
                      style={
                        category.id === "Import" ? { paddingLeft: "20px" } : {}
                      }
                    />

                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map((route, index2) =>
                        route.visible ? (
                          <SidebarLink
                            key={`${String(index)}_${String(index2)}`}
                            name={route.name}
                            to={route.path}
                            icon={route.icon}
                            badge={route.badge}
                            style={{ fontSize: "12px" }}
                          />
                        ) : null
                      )}
                    </Collapse>
                  </React.Fragment>
                ) : (
                  <>
                    {category.id === "Project Management (PMS)" && (
                      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    )}
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.id}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      badge={category.badge}
                    />
                  </>
                )}
              </React.Fragment>
            ))}
          </Items>
        </List>
        <PoweredBy />
        <ZendDesk />
        {/* {open &&} */}
      </Scrollbar>
    </Drawer>
  )
}

export default withRouter(MainSidebar)
